import React from 'react'
import EnterpriseNav from './EnterpriseNav'
import { useParams } from 'react-router-dom'

export default function Summary() {
    const {id} = useParams();
  return (
    <div className='summary'>
        <h1>Booking Summary</h1>
        <h3>Pick Up Information</h3>
        <div className='summary-container'>
            <div>
                <p>Package Name</p>
                {/* <p></p> */}
            </div>
            <div>
                <p>{id}</p>
            </div>
        </div>
    </div>
  )
}
